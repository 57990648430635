import React from 'react';
import ReactDOM from 'react-dom';

const modalStyles = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '20px',
    zIndex: 1000,
    width: '80%', // Sets the width to 80% of the viewport width, effectively creating a 10% margin on each side
    maxWidth: '600px', // Optional: Sets a max width for the modal
    margin: '0 auto', // Centers the modal horizontally
    overflowY: 'auto', // Adds scroll for long content
    maxHeight: '80vh',
};

const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,.7)',
    zIndex: 1000,
};

function HelpModal({ open, children, onClose }) {
    if (!open) return null;

    return ReactDOM.createPortal(
        <>
            <div style={overlayStyles} onClick={onClose} />
            <div style={modalStyles}>
                <button class="helpClose" onClick={onClose} style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '0px',
                    fontSize: '18px'
                }}>Close</button>
                {children}
            </div>
        </>,
        document.getElementById('portal')
    );
}

export default HelpModal;
