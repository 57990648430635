import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import RatingComponent from './MyRatingComponent';
import { useGuid } from './GuidContext'; // Assuming useGuid is available for getting the user's ID



const getTodaysDateInCentralTime = () => {
    const options = { timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date().toLocaleDateString('en-CA', options); // Canadian English uses YYYY-MM-DD by default
    return date;

};

const getYesterdaysDateInCentralTime = () => {
    const options = { timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' };
    const today = new Date();
    today.setDate(today.getDate() - 1); // Subtract one day to get yesterday
    const date = today.toLocaleDateString('en-CA', options); // Canadian English uses YYYY-MM-DD by default
    return date;
};


const DrawingsList = ({ setShowSubject }) => {
    const [drawings, setDrawings] = useState([]);
    const { guid } = useGuid(); // Fetch the user's GUID
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1); // Add a state to track the current page
    const [userRatings, setUserRatings] = useState(() => JSON.parse(localStorage.getItem('userRatings')) || {});
    const [currentDate] = useState(getTodaysDateInCentralTime());
    const [viewingDate, setViewingDate] = useState(getTodaysDateInCentralTime());
    const [subject, setSubject] = useState(''); // State to store the subject of the first drawing
    const firstLoad = useRef(true);
    const itemsPerPage = 20; // Define how many items you want per page
    const fetchMoreDrawings = async () => {
        try {


            // Update the request URL to include pagination parameters
            const response = await axios.get(`https://ibt18xjvyj.execute-api.us-east-1.amazonaws.com/getVotedDoodles?date=${viewingDate}&page=${page}&items_per_page=${itemsPerPage}`);

            const newDrawings = response.data;

            // Append the new drawings to the existing drawings
            setDrawings(prevDrawings => {
                // Update subject if fetching the first page
                if (page === 1 && newDrawings.length > 0) {
                    setSubject(newDrawings[0].drawingSubject); // Assuming 'subject' is the field name in the response
                }
                return [...prevDrawings, ...newDrawings];
            });

            // Increment the page number for the next request
            setPage(p => p + 1);

            // If no new drawings are returned, we've reached the end d
            if (newDrawings.length < itemsPerPage) {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Failed to fetch drawings:', error);
        }
    };


    useEffect(() => {

        setShowSubject(false);
        setDrawings([]);
        setPage(1);
        setHasMore(true);
        fetchMoreDrawings();
        firstLoad.current = false; // Set it to false after the first load

        // Dependence on viewingDate will still trigger this effect when viewingDate changes
    }, [viewingDate]);


    useEffect(() => {

        const lastVisited = localStorage.getItem('lastVisited');
        if (lastVisited !== currentDate) {
            setUserRatings({});
            localStorage.setItem('userRatings', JSON.stringify({}));
            localStorage.setItem('lastVisited', currentDate);
        } else {
            const savedRatings = localStorage.getItem('userRatings');
            if (savedRatings) {
                setUserRatings(JSON.parse(savedRatings));
            }
        }
    }, []);  // This effect runs only once when the component mounts

    const voteForDrawing = async (userId, rating) => {
        try {
            await fetch('https://ibt18xjvyj.execute-api.us-east-1.amazonaws.com/SubmitVote', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: userId,
                    rating: rating,
                    drawingDate: getTodaysDateInCentralTime()
                })
            });

        } catch (error) {
            console.error('Error saving vote:', error);
        }
    };


    const setToday = () => {
        setPage(1)
        setViewingDate(getTodaysDateInCentralTime());
    };

    const setYesterday = () => {
        setPage(1)
        setViewingDate(getYesterdaysDateInCentralTime());
    };

    const today = getTodaysDateInCentralTime();
    const yesterday = getYesterdaysDateInCentralTime();

    const handleRatingSelected = (userId, newRating) => {

        console.log(`Rating selected for guid ${userId}: ${newRating}`);

        // Update the local state for user ratings
        const newUserRatings = { ...userRatings, [userId]: newRating };
        setUserRatings(newUserRatings);

        // Save the new rating to localStorage
        localStorage.setItem('userRatings', JSON.stringify(newUserRatings));

        // Submit the new rating to the server
        voteForDrawing(userId, newRating);
    };




    return (
        <>
            <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
                {viewingDate === today ? (
                    <span>Today</span>
                ) : (
                    <a href="#" onClick={setToday} style={{ cursor: 'pointer', textDecoration: 'underline' }}>Today</a>
                )}
                {' | '}
                {viewingDate === yesterday ? (
                    <span>Yesterday</span>
                ) : (
                    <a href="#" onClick={setYesterday} style={{ cursor: 'pointer', textDecoration: 'underline' }}>Yesterday</a>
                )}
            </div>
            {subject && <div style={{ textAlign: 'center', marginBottom: '10px' }}>{subject}</div>}
            <InfiniteScroll
                dataLength={drawings.length}
                next={fetchMoreDrawings}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>You have seen all the doodles.</b>
                    </p>
                }
            >
                {drawings.map((drawing, index) => (
                    <div key={index} style={{
                        margin: '0px 5px',
                        borderTop: '1px solid black', // Solid border for the top
                        borderLeft: '1px solid black', // Solid border for the left
                        borderRight: '1px solid black', // Solid border for the right
                        borderBottom: 'none', // No border for the bottom
                        padding: '5px' // Add padding to separate the content from the border
                    }}>
                        {drawing.signature && (
                            <div style={{ textAlign: 'center', marginBottom: '5px', }}>
                                {drawing.signature}
                            </div>
                        )}
                        <div style={{ position: 'relative', margin: '5px' }}>
                            <img src={drawing.imageUrl} alt="" style={{ width: '100%', display: 'block' }} />
                            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                <RatingComponent
                                    rating={drawing.rating} // This displays the average rating and is read-only
                                    readOnly // Assuming the RatingComponent supports a readOnly prop
                                    small={true}
                                    averageRating={drawing.rating}
                                    numRaters={drawing.ballotCount}
                                />
                            </div>
                        </div>
                        {viewingDate === getTodaysDateInCentralTime() && guid !== drawing.userId && (
                            <RatingComponent
                                rating={userRatings[drawing.userId] || 0}
                                onRatingSelected={(newRating) => handleRatingSelected(drawing.userId, newRating)}
                                readOnly={!!userRatings[drawing.userId]}
                            />
                        )}
                    </div>
                ))}
            </InfiniteScroll>
        </>
    );
};

export default DrawingsList;
