import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGuid } from './GuidContext';
import RatingComponent from './MyRatingComponent'; // Make sure this path is correct



function MyDoodles({ setShowSubject }) {
    const { guid } = useGuid();
    const [drawings, setDrawings] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1); // Add a state to keep track of the current page for pagination
    const [lastKey, setLastKey] = useState(null);


    useEffect(() => {
        // Reset drawings if GUID changes
        setDrawings([]);
        setPage(1);
        setHasMore(true);
        fetchDrawings(page); // Fetch initial drawings
        setShowSubject(false);
    }, [guid]);

    const fetchDrawings = async (currentPage) => {
        if (!guid) {
            console.warn('GUID is not available yet.');
            return;
        }

        try {
            const queryParams = new URLSearchParams({ userId: guid, page });
            if (lastKey) {
                queryParams.append('LastEvaluatedKey', JSON.stringify(lastKey));
            }

            const response = await fetch(`https://ibt18xjvyj.execute-api.us-east-1.amazonaws.com/GetDoodles?${queryParams.toString()}`);
            const data = await response.json();

            if (data.drawings && Array.isArray(data.drawings)) {
                const newDrawings = data.drawings.map(drawing => ({
                    imageUrl: drawing.imageUrl,
                    rating: drawing.rating,
                    ballotCount: drawing.ballotCount,
                    dailySubject: drawing.drawingSubject
                }));
                setDrawings(drawings => [...drawings, ...newDrawings]);
                setPage(prevPage => prevPage + 1);
                setLastKey(data.LastEvaluatedKey);
                setHasMore(!!data.LastEvaluatedKey);
            } else {
                console.warn("Unexpected data format received:", data);
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching drawings:', error);
            setHasMore(false);
        }
    };

    return (
        <div>

            <InfiniteScroll
                dataLength={drawings.length}
                next={() => fetchDrawings(page)}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={<p style={{ textAlign: 'center' }}>You have seen it all</p>}
                style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '0px' }} // Styles for the grid layout
            >
                {drawings.map((drawing, index) => (
                    <div key={index} style={{
                        margin: '0px 5px',
                        borderTop: '1px solid black', // Solid border for the top
                        borderLeft: '1px solid black', // Solid border for the left
                        borderRight: '1px solid black', // Solid border for the right
                        borderBottom: 'none', // No border for the bottom
                        padding: '5px' // Add padding to separate the content from the border
                    }}>
                        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                            {drawing.dailySubject}
                        </div>
                        <img
                            src={drawing.imageUrl}
                            alt="Drawing"
                            style={{ width: '100%', height: 'auto', cursor: 'zoom-in' }}
                        />
                        {/* Include the RatingComponent for each drawing */}
                        <RatingComponent
                            rating={drawing.rating} readOnly
                            numRaters={drawing.ballotCount}
                            averageRating={drawing.rating}
                        />
                    </div>
                ))}
            </InfiniteScroll>

        </div>
    );
}

export default MyDoodles;
