// ConfirmDialog.js
import React from 'react';
import './ConfirmDialog.css'; // Ensure you create and style this CSS file

const ConfirmDialog = ({ message, onConfirm, onCancel }) => {
    return (
        <div className="confirm-dialog">
            <div className="confirm-dialog-content">
                <p>{message}</p>
                <button onClick={onConfirm}>Yes</button>
                <button onClick={onCancel}>No</button>
            </div>
        </div>
    );
};

export default ConfirmDialog;
