import React, { useState, useEffect } from 'react';
import checkeredPattern from './checkers.png'; // Adjust the path as necessary
import sampleIcon from './sampleIcon.png'; // Import the sample icon
import straightLineIcon from './straightLine.png';
import squiggleLineIcon from './squiggleLine.png';
import horizontalIcon from './horizontal.png';
import undoIcon from './undoIcon.png';
import squareLineIcon from './squareLine.png';
import circleLineIcon from './circleLine.png';
import eraseIcon from './eraseIcon.png'; // Ensure this path is correcct
import clearCanvasIcon from './clearCanvas.png'

import { SketchPicker, SwatchesPicker } from 'react-color';
import reactCSS from 'reactcss';

const lineModes = [
    { id: 'straight', icon: straightLineIcon },
    { id: 'squiggle', icon: squiggleLineIcon },
    { id: 'square', icon: squareLineIcon },
    { id: 'circle', icon: circleLineIcon },
    { id: 'horizontal', icon: horizontalIcon }
];

const hexToRGB = (hex) => {
    let r = 0, g = 0, b = 0;
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    }
    return { r, g, b };
};

const brushSizes = ['XS', 'S', 'M', 'L', 'XL'];
const sizeToDimensionMap = {
    'XS': 10, // Diameter in pixels
    'S': 13,
    'M': 16,
    'L': 20,
    'XL': 23
};

const alphas = [0.1, 0.3, 1.0]; // 10%, 50%, 100%

export const ColorPalette = ({ color, setColor, onBrushSizeChange, onAlphaChange, onUndo, sampleMode, setSampleMode, onLineModeChange, onEraseModeChange, onClearCanvas }) => {
    const [selectedBrushSize, setSelectedBrushSize] = useState(''); // Add state for selected brush size
    const [selectedAlpha, setSelectedAlpha] = useState(0); // Add state for selected alpha
    const [lineMode, setLineMode] = useState('squiggle');
    const [eraseMode, setEraseMode] = useState(false);
    const [selectedLineMode, setSelectedLineMode] = useState('squiggle');
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (color) => {
        setColor(color.hex);
    };



    useEffect(() => {
        setColor("#FF0000");
        setSelectedAlpha(1.0);
        onAlphaChange(1.0);
        setSelectedBrushSize('XS');
        onBrushSizeChange('XS');
    }, []); // Empty dependency array means this runs once on mount

    const handleSelectBrushSize = (size) => {
        setSelectedBrushSize(size);
        onBrushSizeChange(size);
    };

    const handleSelectAlpha = (alpha) => {
        setSelectedAlpha(alpha);
        onAlphaChange(alpha);
    };

    const handleSelectLineMode = (mode) => {
        setSelectedLineMode(mode);
        onLineModeChange(mode);
    };

    const handleToggleSampleMode = () => {
        const newSampleMode = !sampleMode;
        setSampleMode(newSampleMode);
    }

    const handleToggleEraseMode = () => {
        const newEraseMode = !eraseMode;
        setEraseMode(newEraseMode);
        onEraseModeChange(newEraseMode);
    };

    const handleClearCanavs = () => {
        onClearCanvas()
    }

    useEffect(() => {
        onLineModeChange(lineMode); // This will call the function with the updated lineMode
    }, [lineMode]); // Only re-run this effect if lineMode changes

    const handleUndo = () => {
        if (onUndo) {
            onUndo(); // Call the provided onUndo handler
        }
    };

    const alphaBackgroundStyle = (alpha) => {
        const { r, g, b } = hexToRGB('#FFFFFF');
        let displayAlpha = alpha; // Use the actual alpha value for the visual representation
        if (alpha === 1) {
            displayAlpha = 0.9; // Change visual representation for alpha = 1 to appear as 0.9
        } else if (alpha === 0.1) {
            displayAlpha = 0.2;
        } else if (alpha === 0.3) {
            displayAlpha = 0.4;
        }
        return {
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            backgroundImage: `linear-gradient(rgba(${r}, ${g}, ${b}, ${displayAlpha}), rgba(${r}, ${g}, ${b}, ${displayAlpha})), url(${checkeredPattern})`,
            border: alpha === selectedAlpha ? '2px solid cyan' : '2px solid grey',
            cursor: 'pointer'
        };
    };

    const handlePickerClick = (event) => {
        event.stopPropagation(); // This prevents the click from propagating to the `cover` div
    };

    const getEraseModeButtonStyle = () => ({
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        border: eraseMode ? '2px solid cyan' : '2px solid grey',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '4px',
        background: 'white',
    });

    const getSampleModeButtonStyle = () => ({
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        border: sampleMode ? '2px solid cyan' : '2px solid grey',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '4px',
        background: 'white',
    });

    const getLineModeButtonStyle = (mode) => ({
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        border: mode === selectedLineMode ? '2px solid cyan' : '2px solid grey',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '1px',
        background: 'white',
    });

    const getClearCanvasStyle = () => ({
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        border: '2px solid grey',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '4px',
        background: 'white',
    });


    const styles = reactCSS({
        'default': {
            color: {
                width: '30px',
                height: '30px',
                border: '1px solid grey',
                background: `${color}`,
                cursor: 'pointer',
                marginRight: '5px', // Adding some spacing between this div and the next set of controls
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
                top: '100px',
                left: '60px',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    return (
        <div className="palette-z-index">
            <div style={{ display: 'flex', justifyContent: 'center', gap: '5px', marginBottom: '2px', alignItems: 'center' }}>
                <div style={styles.color} onClick={handleClick}></div>
                {displayColorPicker ? (
                    <div style={styles.popover}>
                        <div style={styles.cover} onClick={handleClose}>
                            <div onClick={handlePickerClick} style={{ margin: '20px' }}>
                                <SwatchesPicker color={color} onChange={handleChange} disableAlpha={true} presetColors={[]} />
                            </div>
                        </div>
                    </div>
                ) : null}
                <div style={{ width: '2px', backgroundColor: 'gray', height: '20px', margin: '0 0px' }}></div>
                {brushSizes.map(size => {
                    const dimension = sizeToDimensionMap[size]; // Get the dimension for this size
                    return (
                        <div
                            key={size}
                            onClick={() => handleSelectBrushSize(size)}
                            style={{
                                width: `${dimension}px`, // Set width based on the size
                                height: `${dimension}px`, // Set height based on the size
                                borderRadius: '50%', // Makes the div a circle
                                backgroundColor: '#000', // Solid black color
                                border: size === selectedBrushSize ? '2px solid cyan' : '2px solid black',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: '4px'
                            }}
                        />
                    );
                })}
                <div style={{ width: '2px', backgroundColor: 'gray', height: '20px', margin: '0 0px' }}></div>
                {alphas.map(alpha => (
                    <div
                        key={alpha}
                        onClick={() => handleSelectAlpha(alpha)}
                        style={alphaBackgroundStyle(alpha)}
                    />
                ))}
                <div style={{ width: '2px', backgroundColor: 'gray', height: '20px', margin: '0 0px' }}></div>
                <div
                    onClick={handleUndo}
                    style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        backgroundColor: 'white', // Arbitrary color for the undo button
                        border: '2px solid grey',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '12px',
                    }}
                ><img src={undoIcon} width='20px' height="'20px"></img></div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '5px', marginBottom: '2px', alignItems: 'center', }}>
                {lineModes.map(({ id, icon }) => (
                    <div key={id} style={getLineModeButtonStyle(id)} onClick={() => handleSelectLineMode(id)}>
                        <img src={icon} alt={`${id} line`} style={{ width: '20px', height: '20px' }} />
                    </div>
                ))}
                <div style={{ width: '2px', backgroundColor: 'gray', height: '20px', margin: '0 0px' }}></div>

                <div style={getEraseModeButtonStyle()} onClick={handleToggleEraseMode}>
                    <img src={eraseIcon} alt="erase" style={{ width: '20px', height: '20px' }} />
                </div>
                <div style={{ width: '2px', backgroundColor: 'gray', height: '20px', margin: '0 0px' }}></div>

                <div style={getSampleModeButtonStyle()} onClick={handleToggleSampleMode}>
                    <img src={sampleIcon} alt="erase" style={{ width: 'auto', height: '17px' }} />
                </div>
                <div style={{ width: '2px', backgroundColor: 'gray', height: '20px', margin: '0 0px' }}></div>

                <div style={getClearCanvasStyle()} onClick={handleClearCanavs}>
                    <img src={clearCanvasIcon} alt="erase" style={{ width: 'auto', height: '20px', marginLeft: '-2px' }} />
                </div>
            </div>
        </div>
    );
};
