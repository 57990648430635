import React from 'react';
import blankStar from './emptystar1.png';
import filledStar from './star.png';
import halfFilledStar from './halfstar.png';

const MyRatingComponent = ({ rating, onRatingSelected, readOnly = false, small = false, averageRating, numRaters }) => {
    const size = small ? '15px' : '30px'; // Size based on `small`

    const generateStars = () => {
        let stars = [];
        // Determine full stars to render, rounding up if fractional part > 0.6
        let fullStars = Math.floor(rating);
        let fractionalPart = (rating % 1).toFixed(1);
        let halfStar = fractionalPart >= 0.4 && fractionalPart <= 0.6;
        if (fractionalPart > 0.6) {
            fullStars += 1;
        }

        for (let i = 1; i <= 5; i++) {
            let starImage = blankStar;
            if (i <= fullStars) {
                starImage = filledStar;
            } else if (i === fullStars + 1 && halfStar) {
                starImage = halfFilledStar;
            }

            stars.push(
                <img
                    key={i}
                    src={starImage}
                    alt={`${i} Star`}
                    onClick={!readOnly ? () => onRatingSelected && onRatingSelected(i) : undefined}
                    style={{ cursor: readOnly ? 'default' : 'pointer', width: size, height: size }}
                />
            );
        }
        return stars;
    };


    // Conditional rendering for averageRating and numRaters
    const renderRatingInfo = () => {
        if (averageRating === undefined || numRaters === undefined) {
            return null; // Do not render anything if either prop is not provided.
        }

        // Adjusted toFixed precision to 1 for a more common rating display
        return (
            <span>Avg: {averageRating.toFixed(2)} ({numRaters} {numRaters === 1 ? 'rater' : 'raters'})</span>
        );
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px', background: 'transparent' }}>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
                {generateStars().map((star) => star)}
            </div>
            {readOnly && (
                <div style={{ marginTop: '5px' }}>
                    {renderRatingInfo()}
                </div>
            )}
        </div>
    );
};

export default MyRatingComponent;
