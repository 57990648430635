import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCookie, setCookie } from './helper.js';

const GuidContext = createContext();

export const useGuid = () => {
    return useContext(GuidContext);
};

// Function to generate a GUID
function generateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const GuidProvider = ({ children }) => {
    const [guid, setGuid] = useState(null);

    useEffect(() => {
        let currentGuid = getCookie('guid');
        if (!currentGuid) {
            // Generate a new GUID and set it in both the state and cookie  
            currentGuid = generateGuid();
            setCookie('guid', currentGuid);
        }
        setGuid(currentGuid);
    }, []);

    // Since you are no longer fetching the GUID or setting an existing one via a network request,
    // you might not need `fetchNewGuid` or `setExistingGuid` functions. If you still require
    // functionality to manually set a GUID (for example, from user input), you could keep
    // a simplified version of `setExistingGuid`.

    const value = { guid };
    return (
        <GuidContext.Provider value={value}>
            {children}
        </GuidContext.Provider>
    );
};